import classNames from 'classnames';
import React, { FC, HTMLAttributes, LegacyRef } from 'react';
import { ContextMenu } from '../context-menu/ContextMenu';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { IoReorderThreeSharp } from 'react-icons/io5';

type ListItemT = {
  id: string;
  name: string;
  actions?: { name: string; onClick: () => void; isRed?: boolean }[];
};
type OrderableListProps = {
  items: ListItemT[];
  changeOrder: (id: string, idxFrom: number, idxTo: number) => void;
} & HTMLAttributes<HTMLDivElement>;
type ListItemProps = {
  item: ListItemT;
  ref: LegacyRef<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;
const ListItem = React.forwardRef<any, ListItemProps>(
  ({ item, ref, ...rest }, innerRef) => {
    return (
      <div
        ref={innerRef}
        className="tw-p-5 tw-mt-3 tw-bg-gray-100 tw-border tw-border-gray-200 tw-font-700 tw-rounded-lg"
        {...rest}
      >
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex tw-items-center">
            <div className={'tw-text-gray-500 tw-mr-3'}>
              <IoReorderThreeSharp size={26} />
            </div>
            {item.name}
          </div>
          <div className="tw-pt-2 tw-font-400 tw-text-left">
            <ContextMenu>
              {item.actions?.map((a) => (
                <div
                  className={classNames({ ['tw-text-red-500']: a.isRed })}
                  onClick={a.onClick}
                >
                  {a.name}
                </div>
              ))}
            </ContextMenu>
          </div>
        </div>
      </div>
    );
  }
);

export const OrderableList: FC<OrderableListProps> = ({
  items,
  changeOrder,
  ...rest
}) => {
  if (!items) {
    return null;
  }
  const grid = 8;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid * 1.5}px 0`,
    ...draggableStyle,
  });

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    changeOrder(
      items[result.source.index].id,
      result.source.index,
      result.destination.index
    );
  };
  return (
    <div {...rest}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      item={item}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
