import React from 'react';
import s from './SimpleTable.module.css';
import { RoundedContainer } from '../rounded-container/RoundedContainer';

type Props = {
  cols: string[];
  rows: any[];
};

export const SimpleTable: React.FC<Props> = ({ cols, rows }) => {
  return (
    <div className={s.container}>
      <RoundedContainer className={'rounded-container'}>
        <table>
          <tbody>
            <tr>
              {cols.map((col, index) => (
                <td key={index}>
                  <strong>{col}</strong>
                </td>
              ))}
            </tr>
            {rows.map((row, index) => (
              <tr key={index}>
                {row.map((td: any, index: number) => (
                  <td key={index}>{td}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </RoundedContainer>
    </div>
  );
};
